function b64DecodeUnicode(str: string) {
	return decodeURIComponent(atob(str).split('').map(function(c) {
		return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
	}).join(''));
}

// @ts-ignore
window.config = {
	setDefault() {
		localStorage.setItem('config', JSON.stringify({
			'api_key': 'pk_test_4c028abaaac94d7ba856',
			'api_secret': 'sk_test_a566c8a9d0e6ae98cc62',
			'application_key': 'jJafLXdzgNeilBAcR5cTwzGGcsLJ6cpm',
			'customer_id': '2ae99b66-66f0-4a17-b302-5fae15093081',
			'capabilities': 'screening',
		}));
	},
};

export function getConfig() {
	const url = window.location.href;
	const isContainHash = url.includes('#');
	if (isContainHash) {
		const b64 = url.split('#')[1];
		const config = JSON.parse(b64DecodeUnicode(b64));
		localStorage.setItem('config', JSON.stringify(config));
	}

	const item: any = localStorage.getItem('config');
	const config = item && JSON.parse(item);
	let username;
	let password;
	let applicationKey;
	let customer_id;
	let capabilities;

	if (config) {
		username = config.api_key;
		password = config.api_secret;
		applicationKey = config.application_key;
		customer_id = config.customer_id;
		capabilities = config.capabilities;
	} else {
		username = localStorage.getItem('api_key');
		password = localStorage.getItem('api_secret');
		applicationKey = localStorage.getItem('application_key');
		customer_id = localStorage.getItem('customer_id');
	}

	if (
		username === null ||
		password === null ||
		applicationKey === null ||
		customer_id === null
	) {
		console.error('Data not found in localStorage');
		throw new Error('Data not found in localStorage');
	}

	return {
		username,
		password,
		applicationKey,
		customer_id,
		capabilities,
	};
}
