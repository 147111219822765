import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import { Verify } from '@hiveid/verify-js';

import { getConfig } from 'src/config';
import { Logo } from './ui/logo';
import { WelcomeBlock } from 'src/ui/welcome-block';

const BASE_URL = 'https://api.hive.id';
const SENTINEL_HOST = BASE_URL;
const VERIFY_HOST = BASE_URL;

function App() {
	const [isComplete, changeIsComplete] = useState(false);
	const sdkContainerRef = useRef(null);
	useEffect(() => {
		const { applicationKey, capabilities } = getConfig();

		if (window.innerWidth < 600) {
			const root = document.getElementById('root');
			const body = document.querySelector('body');
			// @ts-ignore
			root.style.height = window.innerHeight + 'px';
			// @ts-ignore
			body.style.height = window.innerHeight + 'px';
		}

		Verify.configure(applicationKey);
		Verify.on('completed', async (payload: { sessionId: string }) => {
			const { username, password } = getConfig();

			const res = await axios.get(`${SENTINEL_HOST}/token`, {
				auth: { username, password },
			});
			const authToken = res.data.access_token;

			axios.post(`${VERIFY_HOST}/checks` + (capabilities ? `?capabilities=${capabilities}` : ''), {
				session_id: payload.sessionId,
			}, {
				headers: {
					authorization: `Bearer ${authToken}`,
				},
			});
			changeIsComplete(true);
		});
	}, []);

	const handleGuestContinue = async () => {
		const container = sdkContainerRef.current;
		if (container === null) { return; }

		const { username, password, customer_id, applicationKey } = getConfig();

		const res = await axios.get(`${SENTINEL_HOST}/token`, {
			auth: { username, password },
		});
		const authToken = res.data.access_token;
		const { data } = await axios.post(`${VERIFY_HOST}/sessions`, { customer_id, application_key: applicationKey }, {
			headers: {
				authorization: `Bearer ${authToken}`,
			},
		});
		const token = data.session_token;

		Verify.mount(container, token);
	};

	return (
		<>
			<header className="header container">
				<Logo/>
			</header>
			<WelcomeBlock
				handleReset={() => changeIsComplete(false)}
				isComplete={isComplete}
				handleStartVerification={handleGuestContinue}
			/>
			<footer className="footer">
				<div className={'footer-content'}>
					<div>
						<HiveLogo/>
					</div>
					<p>
						This is a demo of Hive Verify. To
						learn more or sign up, please <a target="_blank" rel="noopener noreferrer" href="https://hive.id">click here</a>.
					</p>
				</div>
			</footer>
			<div id="verify-container" ref={sdkContainerRef}/>
		</>
	);
}

const HiveLogo = () => (
	<svg width="66" height="28" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path fillRule="evenodd" clipRule="evenodd"
		      d="M2.42 9.7v9.9l8.35 4.83 3.76 2.17L12.1 28 0 21v-9.9l2.42-1.4zm21.79 8.5V21l-8.54 4.94-2.43-1.4 7.33-4.24 3.64-2.1zM12.17 4.08l8.6 4.97v9.92l-8.6 4.97-8.6-4.97V9.04l8.6-4.97zm-.04 2.68l-6.27 3.62v7.26l6.27 3.62 6.28-3.62v-7.26l-6.28-3.62zM48.16 8.9c.2 0 .37.05.5.14.12.1.22.22.28.37l1.98 5.46a12 12 0 01.52 1.67 12.6 12.6 0 01.49-1.67l1.96-5.46a.85.85 0 01.28-.35c.13-.1.3-.16.49-.16h1.92l-3.45 8.59-2.79 1.6-4.09-10.2h1.9zM66 17.44v1.65h-6.5v-.3l2.34-1.35H66zM35.84 8.9l2.38 1.37v7.44l-2.38 1.37v-4.3h-4.15v4.27l-2.39-1.37V10.3l2.39-1.37v4.22h4.15V8.91zm7.82 0v8.75-.01l-2.38 1.37v-8.74l2.38-1.37zm-31.52.76a2.2 2.2 0 01.92 4.19l1.27 4.6h-4.4l1.28-4.6a2.2 2.2 0 01.93-4.19zM12.1 0l12.1 7v9.98l-2.41 1.4V8.4L12.1 2.8 9.68 1.4 12.1 0zM66 13.1v1.66h-6.5v-1.65H66zm0-4.2v1.66h-4.16L59.5 9.21v-.3H66zM8.6 2.03l2.43 1.4-8.4 4.85L0 9.8V7l8.6-4.98z"
		      fill="#6E37D8"/>
	</svg>
);

export default App;
